













































































































































































































































































import {Vue, Component} from "vue-property-decorator";
import {Filtro} from "@/Classes/FiltroClass";
import StruttureService, {StruttureResponse} from "@/services/StruttureService";
import SportService from "@/services/SportService";
import Toolbar from "@/components/Toolbar.vue";
import TorneiService, {TorneiResponse} from "@/services/TorneiService";
import {DateTime} from "luxon";
import PrenotazioneService from "@/services/PrenotazioneService";
import UtentiService from "@/services/UtentiService";
import SquadreService from "@/services/SquadreService";

interface Form {
  nome: string
  utenteId: number|null
  torneoId: number|null
  capitano: string
  squadra: string
}

@Component({
  components: {
    Toolbar,
  },
})
export default class Tournaments extends Vue{

  public datiStruttura: StruttureResponse = []
  public filtro = new Filtro();
  public tornei: TorneiResponse[] = []
  public torneoDaModificare: any = {}
  public dialogDettagli: boolean = false
  public dialogIscrizioni: boolean = false
  public dialogDettagliSquadre: boolean = false
  public dialogModifica: boolean = false
  public idTorneoSelezionato: number = 0
  public idTorneoSelezionato2: number = 0
  public utente: boolean = false
  public giorni: string[] = []
  public loading: boolean = false;
  public listaUtenti: any = [];
  public formIscrizione: Form = {
    nome: "",
    utenteId: null,
    torneoId: null,
    capitano: "",
    squadra: ""
  }
  public giorniSettimana: string[] = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"]

  public datePickerOptions: any = {
    disabledDate(date: any) {
      return date < new Date().setDate(new Date().getDate() - 1)
    }
  }
  public datePickerOptionsDataFine: any = {}
  public datePickerOptionsFineIscrizione: any = {}

  public async disableDataFine() {
    const dataInizio = this.torneoDaModificare.data_inizio_torneo
    this.datePickerOptionsDataFine = {
      disabledDate(date: any) {
        return date < dataInizio
      }
    }
    if(this.torneoDaModificare.data_inizio_torneo > this.torneoDaModificare.data_fine_torneo) {
      this.torneoDaModificare.data_fine_torneo = this.torneoDaModificare.data_inizio_torneo
    }
  }
  public async disableDineIscrizione(){
    const dataInizio = this.torneoDaModificare.data_inizio_iscrizione
    this.datePickerOptionsFineIscrizione = {
      disabledDate(date: any) {
        return date < dataInizio
      }
    }
    if(this.torneoDaModificare.data_inizio_iscrizione > this.torneoDaModificare.data_fine_iscrizione) {
      this.torneoDaModificare.data_fine_iscrizione = this.torneoDaModificare.data_inizio_iscrizione
    }
  }
  public async RiceviStrutture(){
    try{
      const response = await StruttureService.getStrutture();

      if(response.error) {
        this.$message.error(response.msg)
      } else{
        this.datiStruttura = response.data;
        this.filtro.id_struttura = this.datiStruttura[0].struttura.value
        await this.RiceviTornei()
      }
    }
    catch (err:any)
    {
      this.$router.push("/error")
    }
  }

  public async RiceviTornei(){
    try{
      const response = await TorneiService.getTornei(this.filtro.id_struttura)
      console.log(response)
      if(response.error) {
        this.$message.error(response.msg)
      } else {
        this.tornei = []
        this.giorni = []
        this.tornei = response.data
        for(let i = 0; i<this.tornei.length; i++) {
          this.tornei[i].data_inizio_torneo= DateTime.fromISO(this.tornei[i].data_inizio_torneo).toFormat("dd/MM/yyyy")
          this.tornei[i].data_fine_torneo= DateTime.fromISO(this.tornei[i].data_fine_torneo).toFormat("dd/MM/yyyy")
          this.tornei[i].data_inizio_iscrizione= DateTime.fromISO(this.tornei[i].data_inizio_iscrizione).toFormat("dd/MM/yyyy")
          this.tornei[i].data_fine_iscrizione= DateTime.fromISO(this.tornei[i].data_fine_iscrizione).toFormat("dd/MM/yyyy")
          this.tornei[i].rapporto_partecipanti = this.tornei[i].squadre.length + "/" + this.tornei[i].max_squadre
          this.tornei[i].prezzoString = this.tornei[i].prezzo/100 + " €"
          if(this.tornei[i].giorni_settimana != undefined) {
            for(let y = 0; y < this.tornei[i].giorni_settimana.length; y++)
            {
              if(this.tornei[i].giorni_settimana[y]){
                if (this.giorni[i] == null)
                  this.giorni[i] = this.giorniSettimana[y]
                else
                  this.giorni[i] = this.giorni[i] + ", " + this.giorniSettimana[y]
              }
            }
          }
        }
        console.log("tornei ", this.tornei)
        const loading = this.$loading({
          lock: true,
        });
        setTimeout(async () => {
          loading.close();
        }, 1000);
      }
    } catch (err: any) {

    }
  }

  async ricercaUtenti(filtro: string) {
    try {
      this.loading = true;
      this.listaUtenti = []
      if (filtro.length > 2) {
        const responseUtenti = await UtentiService.getUtenti(filtro.toUpperCase());
        this.loading = false;
        this.listaUtenti = responseUtenti.data.map(x => {
          return {
            label: `${x.cognome} ${x.nome} -  ${x.cellulare}`,
            value: x.id
          }
        })
      }
    } catch (error: any) {
      this.loading = false;
      this.$message.error(error.message);

    }
  }

  public async IscriviSquadra(){
    this.formIscrizione.torneoId =this.idTorneoSelezionato2
    if(this.utente && this.formIscrizione.utenteId != null){
      const response = await UtentiService.getUtenteById(this.formIscrizione.utenteId)
      if(response.error){
        this.$message.error(response.msg)
      } else {
        this.formIscrizione.capitano = response.data[0].nome + " " + response.data[0].cognome
      }
    }
    else if (!this.utente) {
      this.formIscrizione.utenteId = null
    }
    console.log(this.formIscrizione)
    const response1 = await SquadreService.AggiungiSquadra(this.formIscrizione)
    if(response1.error){
      this.$message.error(response1.msg)
    } else {
      this.$message.success(response1.data)
      this.dialogIscrizioni = false
      this.formIscrizione = {
        nome: "",
        utenteId: null,
        torneoId: null,
        capitano: "",
        squadra: ""
      }
      await this.RiceviTornei()
    }
  }

  public async addTorneo(){
    this.$router.push("/aggiungiTorneo")

  }

  public async ApriDialogInfo(torneo: any){
    this.idTorneoSelezionato = torneo.$index
    this.dialogDettagli = true
  }

  public async ApriDialogModifica(torneo: any){
    this.idTorneoSelezionato = torneo.$index
    this.torneoDaModificare = {
      nome:this.tornei[this.idTorneoSelezionato].nome,
      descrizione:this.tornei[this.idTorneoSelezionato].descrizione,
      data_inizio_torneo: DateTime.fromFormat(this.tornei[this.idTorneoSelezionato].data_inizio_torneo, 'dd/MM/yyyy').toJSDate(),
      data_fine_torneo:DateTime.fromFormat(this.tornei[this.idTorneoSelezionato].data_fine_torneo, 'dd/MM/yyyy').toJSDate(),
      data_inizio_iscrizione: DateTime.fromFormat(this.tornei[this.idTorneoSelezionato].data_inizio_iscrizione, 'dd/MM/yyyy').toJSDate(),
      data_fine_iscrizione: DateTime.fromFormat(this.tornei[this.idTorneoSelezionato].data_fine_iscrizione, 'dd/MM/yyyy').toJSDate(),
      orario: this.tornei[this.idTorneoSelezionato].orario,
      max_squadre: this.tornei[this.idTorneoSelezionato].max_squadre,
      premi: this.tornei[this.idTorneoSelezionato].premi,
      strutturaId: this.filtro.id_struttura
    }
    this.dialogModifica = true
  }

  public async ApriDialogIscrizioni(torneo: any){
    this.idTorneoSelezionato2 = torneo.row.id
    this.dialogIscrizioni = true
  }

  public async ApriDettagliSquadre(){
    this.dialogDettagliSquadre = true
  }

  async CancellaTorneo(torneo: any) {
    try {
      this.$confirm("Fai click su conferama per procedere", "Sei sicuro di voler eliminare il torneo?", {
        confirmButtonText: "Conferma",
        cancelButtonText: "Indietro"
      }).then(async () => {
        const response = await TorneiService.deleteTorneo(torneo.row.id)
        if(response.error) {
          this.$message.error(response.msg)
        } else {
          this.$message.success(response.data)
          await this.RiceviTornei()
        }
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: "L'elemento non è stato eliminato"
        })
      })
    } catch (error: any) {

    }
  }

  async updateTorneo() {
    const response = await TorneiService.updateTorneo(this.tornei[this.idTorneoSelezionato].id, this.torneoDaModificare)
    if(response.error) {
      this.$message.error(response.msg)
    } else {
      this.$message.success(response.data)
      this.dialogModifica = false
      await this.RiceviTornei()
    }  }

  async DeleteSquadra(squadre: any){
    console.log("sdfvuhrsopih", squadre.row)
    const response = await SquadreService.EliminaSquadra(squadre.row.id, this.tornei[this.idTorneoSelezionato].id)
    if(response.error) {
      this.$message.error(response.msg)
    } else {
      this.$message.success(response.data)
      await this.RiceviTornei()
    }
  }
  async mounted(){
    this.RiceviStrutture();
  }
}
