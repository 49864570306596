import BaseService, { APIResponse } from "./BaseService";

export default class SquadreService extends BaseService {

  static async AggiungiSquadra(body: any): Promise<APIResponse<any>> {
    return this.perform<any>( {
      url: "operatore/squadre",
      method: "post",
      body: body
    })
  }

  static async EliminaSquadra(id: number, torneoId: number): Promise<APIResponse<any>> {
    return this.perform({
      url: "operatore/squadre/elimina",
      method: 'post',
      body: {
        id: id,
        torneoId: torneoId
      }
    })
  }
}
